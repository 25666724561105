import { run, hasSufficientPerformance, setup } from '#booster/utils/performance';
import { triggerRunCallback, observeBoosterButton, setupBoosterLayer, updateBoosterLayerMessage, initReducedView, hasBatteryPerformanceIssue, deprecationWarningButtonSelector } from '#booster/utils/entry';
import Deferred from '#booster/classes/Deferred';
import { isSupportedBrowser } from '#booster/utils/browser';
import {video as videoBlob} from './blobs.mjs';


  // vite
export default entryWrapper();

function entryWrapper(){

  if (!process.server) {
    return client().then(() => getEntry());
  } else {
    return async (ctx) => (await getEntry())(ctx)
  }

};

function getEntry(){
  return import('/home/vsts/work/1/s/node_modules/nuxt/dist/app/entry').then(module => module.default);
}

function client () {
  const deferred = new Deferred();

  let initialized = false
  const layerEl = window.document.getElementById('nuxt-booster-layer');

  const forceInit = ('__NUXT_BOOSTER_FORCE_INIT__' in window && window.__NUXT_BOOSTER_FORCE_INIT__);

  async function initApp(force) {

    if (initialized) {
      deferred.resolve();
    }

    document.documentElement.classList.remove('nuxt-booster-reduced-view');

    
    try {
if (!force) {
        await run();
      }


      initialized = true;

      triggerRunCallback(true);

      deferred.resolve();

    } catch (error) {

      console.warn(error)

      triggerRunCallback(false);

      if (!!layerEl) {
        // User must interact via the layer.
        updateBoosterLayerMessage(layerEl, 'nuxt-booster-message-weak-hardware');
        return null;
      }
    }

    return null;
  };

  const supportedBrowser = isSupportedBrowser({"regex":""});

  window.addEventListener('load', function () {
    if (!document.getElementById('nuxt-booster-layer')) {
      initApp(forceInit);
    } else {

      observeBoosterButton('.nuxt-booster-button-init-reduced-view', initReducedView);
      observeBoosterButton('.nuxt-booster-button-init-app', () => initApp(true));

      /* id selector will removed in future */
      deprecationWarningButtonSelector(initApp);

      setup({"timing":{"fcp":800,"dcl":1200}});

      if(('__NUXT_BOOSTER_AUTO_INIT__' in window && window.__NUXT_BOOSTER_AUTO_INIT__) || ((true && hasSufficientPerformance()) && supportedBrowser)) {
        initApp();
      } else {
        setupBoosterLayer(layerEl, supportedBrowser)
      }

    }
  });

return deferred.promise;

}
